import React from "react"
import PropTypes from "prop-types"

const Broken = (props) => {
  const accent = props.stress_syllable.indexOf("X")
  const secondary = props.stress_syllable.indexOf("s")
  const syllables = props.broken.split(" ")

  syllables[
    accent
  ] = `<span class="has-text-weight-bold">${syllables[accent]}</span>`
  if (secondary > -1) {
    syllables[
      secondary
    ] = `<span class="has-text-weight-semibold">${syllables[secondary]}</span>`
  }

  const highlighted = syllables.join(" ")

  return <span dangerouslySetInnerHTML={{ __html: highlighted }}></span>
}

Broken.propTypes = {
  stress_syllable: PropTypes.string.isRequired,
  broken: PropTypes.string.isRequired,
}

export default Broken
