import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FaThLarge, FaPlayCircle, FaBook } from "react-icons/fa"
import PropTypes from "prop-types"
import Player from "./player"
import Stars from "./stars"
import Broken from "./broken"
import CardBack from "../images/card_back.svg"

const StyledTitle = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0.7;
  margin-left: 1rem;
`

const SentenceWrapper = styled.div`
  padding: 0.25rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
`

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
`

const StyledCardHeader = styled.div`
  border: solid 1px none;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
`

const CardImg = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 12rem;
`

const StarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const StyledCardContent = styled.div`
  margin-top: 2rem;
`

const WordWrapper = styled.div`
  padding: 0.5rem;
  font-size: 1.5rem;
`

const LinkWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
`

const PracticeCard = (props) => {
  let music = props.music
  if (props.music === false) {
    music = `${props.content.pattern}_MUSIC`
  }
  return (
    <div
      className="column has-text-centered is-half"
      style={{ paddingLeft: "10%", paddingRight: "10%" }}
      key={props.content.title}
    >
      <StyledCard>
        <StyledCardHeader
          className={
            props.color ? "has-background-primary" : "has-background-info"
          }
        >
          <div className="columns is-flex-touch">
            <div className="column">
              <StyledTitle className="is-pulled-left has-text-black">
                {props.content.title}
              </StyledTitle>
              <StarWrapper className="is-pulled-right">
                <Stars pattern={props.content.pattern} />
              </StarWrapper>
            </div>
          </div>
          <div className="columns is-flex-touch">
            <div className="column is-family-secondary is-size-3 has-text-grey-darker">
              <Player source={props.content.example_word}>
                {props.content.example_word}
              </Player>
            </div>
          </div>
        </StyledCardHeader>
        <CardImg src={props.content.image} alt="example sentence usage">
          <div className="columns">
            <div className="column">
              {props.content.sentence ? (
                <SentenceWrapper className="has-text-black">
                  <Player source={props.content.sentence_audio} icon>
                    {props.content.sentence}
                  </Player>
                </SentenceWrapper>
              ) : undefined}
            </div>
          </div>
        </CardImg>
        <StyledCardContent>
          {props.method ? (
            <Player
              key={`${props.method}-${music}`}
              source={music}
            >
              <FaPlayCircle className="icon is-medium" color="#fc7059" />
            </Player>
          ): (
            <Player key={`${props.method}-${props.content.setaudio}`} source={props.content.setaudio}>
              <FaPlayCircle className="icon is-medium" color="#fc7059" />
            </Player>
          )}
          {props.content.wordset.map((word) => {
            const wordData = props.words.edges.find((element) => {
              return element.node.word === word
            })
            if (wordData !== undefined) {
              return (
                <WordWrapper key={word}>
                  <Player source={word} icon>
                    <Broken
                      broken={wordData.node.broken}
                      stress_syllable={wordData.node.stress_syllable}
                    />
                    <a
                      className="icon is-small has-text-light"
                      href={`https://www.ldoceonline.com/dictionary/${word}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaBook />
                    </a>
                  </Player>
                </WordWrapper>
              )
            }
            return (
              <WordWrapper key={word}>
                {word}{" "}
                <a
                  className="icon is-small has-text-light"
                  href={`https://www.ldoceonline.com/dictionary/${word}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaBook />
                </a>
              </WordWrapper>
            )
          })}
          <LinkWrapper>
            <Link to={props.content.speedgrid}>
              <FaThLarge size="2.5em" />
            </Link>
            {props.content.game ? (
              <>
                &emsp;
                <Link to={props.content.game}>
                  <img
                    className="icon has-text-info is-large"
                    src={CardBack}
                    alt="back of playing card"
                  />
                </Link>
              </>
            ) : undefined}
          </LinkWrapper>
        </StyledCardContent>
      </StyledCard>
    </div>
  )
}
PracticeCard.propTypes = {
  content: PropTypes.object.isRequired,
  words: PropTypes.object.isRequired,
  color: PropTypes.bool,
  method: PropTypes.bool,
}
PracticeCard.defaultProps = {
  color: false,
  method: false,
}

export default PracticeCard
