import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { FaTh } from "react-icons/fa"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PracticeCard from "../components/practicecard"
import { WordsMusicToggle } from "../components/toggle"
import dash from "../images/dash.svg"
import CardBack from "../images/card_back.svg"
import Stars from "../components/stars"
import Demo from "../components/demo"

const HeaderWrapper = styled.div`
  padding-right: 6rem;
  padding-left: 6rem;
  padding-top: 1rem;
  @media (max-width: 769px) {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
  }
`

const ToggleWrapper = styled.div`
  @media (max-width: 769px) {
    margin-top: 3rem;
  }
`

const StyledHeader = styled.b`
  font-size: 2rem;
  margin-right: 0.75rem;
`

const Dash = styled.img`
  width: 5rem;
  display: block;
`

const StyledButton = styled.a`
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
`

const isEven = (value) => {
  if (value % 2 === 0) return true
  return false
}

function PracticepageTemplate(props) {
  const [method, setMethod] = useState(false)
  const toggle = () => setMethod(!method)
  const [music, setMusic] = useState(() => {
    if (props.data.strapiPracticepage.Music[0]) {
      return props.data.strapiPracticepage.Music[0].Url
    }
    return false
  })
  const splitPageName = props.data.strapiPracticepage.Name.split("Pattern")
  let title = props.data.strapiPracticepage.Name
  let stars = false
  if (splitPageName[1] !== undefined) {
    title = splitPageName[0]
    stars = true
  }
  let count = -1
  return (
    <Layout>
      <SEO title={props.data.strapiPracticepage.Name} />
      <HeaderWrapper
        className="columns"
        style={
          props.data.strapiPracticepage.Demo.length === 0
            ? { paddingBottom: "4rem" }
            : undefined
        }
      >
        <div className="column has-text-centered">
          <h2 className="subtitle is-4 is-pulled-left">
            <StyledHeader>Practice:</StyledHeader>
            {title}
            {stars ? <Stars pattern={splitPageName[1]} /> : undefined}
            <Dash src={dash} alt="some dashes" />
          </h2>
        </div>
        <ToggleWrapper className="column">
          <div className="dropdown is-hoverable is-right is-pulled-right">
            <div className="dropdown-trigger">
              <div
                role="button"
                tabIndex="0"
                onClick={toggle}
                onKeyPress={toggle}
              >
                <WordsMusicToggle toggle={method ? undefined : true} />
              </div>
            </div>

            {method ? (
              <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {props.data.strapiPracticepage.Music.map((audio) => (
                    <StyledButton
                      href="#"
                      key={audio.Url}
                      className={
                        music === audio.Url
                          ? " dropdown-item is-active"
                          : "dropdown-item"
                      }
                      onClick={() => setMusic(audio.Url)}
                      onKeyPress={() => setMusic(audio.Url)}
                    >
                      {audio.Name}
                    </StyledButton>
                  ))}
                </div>
              </div>
            ) : undefined}
          </div>
        </ToggleWrapper>
      </HeaderWrapper>
      {props.data.strapiPracticepage.Demo.length > 0 ? (
        <Demo items={props.data.strapiPracticepage.Demo} />
      ) : undefined}
      <div className="columns is-8 is-multiline is-variable">
        {Object.keys(props.data.strapiPracticepage.Content.content).map(
          (_, val) => {
            const id = `panel-${val}`
            if (isEven(val) === false) {
              count += 1
              return (
                <React.Fragment key={id}>
                  <PracticeCard
                    method={method}
                    words={props.data.allStrapiWord}
                    content={props.data.strapiPracticepage.Content.content[val]}
                    music={music}
                  />
                  <div className="column has-text-centered is-full">
                    <Link
                      to={
                        props.data.strapiPracticepage.Content.combined_grids[
                          count
                        ]
                      }
                    >
                      <FaTh size="3em" />
                    </Link>
                  </div>
                </React.Fragment>
              )
            }
            return (
              <PracticeCard
                key={id}
                method={method}
                words={props.data.allStrapiWord}
                content={props.data.strapiPracticepage.Content.content[val]}
                music={music}
                color
              />
            )
          }
        )}
      </div>
      <div className="column has-text-centered is-full">
        <h3 className="is-size-3">PRACTICE ALL WORDS</h3>
        <Link
          to={props.data.strapiPracticepage.Content.combined_grids.slice(-1)[0]}
        >
          <FaTh size="3em" />
        </Link>
        {props.data.strapiPracticepage.Content.combined_games ? (
          <>
            &emsp;
            <Link
              to={
                props.data.strapiPracticepage.Content.combined_games.slice(
                  -1
                )[0]
              }
            >
              <img
                className="icon has-text-info is-large"
                src={CardBack}
                alt="back of playing card"
              />
            </Link>
          </>
        ) : undefined}
      </div>
    </Layout>
  )
}
PracticepageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PracticepageTemplate

export const query = graphql`
  query PracticepageTemplate($id: String!) {
    strapiPracticepage(id: { eq: $id }) {
      id
      Name
      Content {
        combined_grids
        combined_games
        content {
          image
          title
          pattern
          sentence
          setaudio
          speedgrid
          example_word
          sentence_audio
          wordset
          game
        }
      }
      Demo {
        MP3
        id
        Text
      }
      Music {
        Url
        Name
      }
    }
    allStrapiWord {
      edges {
        node {
          id
          word
          syllables
          suffix
          stress_syllable
          broken
        }
      }
    }
  }
`
