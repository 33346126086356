import React from "react"
import PropTypes from "prop-types"
import { FaAngleDown } from "react-icons/fa"
import Player from "./player"

const Demo = (props) => (
  <div
    className="columns has-text-left"
    style={{ paddingLeft: "10%", paddingRight: "10%" }}
  >
    <div className="column">
      <div className="dropdown is-hoverable m-1">
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            style={{ borderColor: "#fc7059" }}
          >
            <span>DEMO: Words+Music</span>
            <span className="icon is-small">
              <FaAngleDown aria-hidden="true" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {props.items.map((item) => {
/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ return (
                <a key={item.id} className="dropdown-item">
                  <Player source={item.MP3}>{item.Text}</Player>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
)

Demo.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Demo
