import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FaVolumeOff } from "react-icons/fa"

const AudioIconWrapper = styled.div`
  opacity: 0;
`

const AudioComponentWrapper = styled.div`
  :hover ${AudioIconWrapper} {
    opacity: 1;
  }
`

class Player extends React.Component {
  state = {
    play: false,
  }

  componentDidMount() {
    this.audio = new Audio(
      `https://hounddogreading.s3-us-east-2.amazonaws.com/${this.props.source}.mp3`
    )
    this.audio.addEventListener("ended", () => this.setState({ play: false }))
  }

  componentWillUnmount() {
    this.audio.removeEventListener("ended", () =>
      this.setState({ play: false })
    )
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause()
      this.audio.currentTime = 0
    })
  }

  render() {
    if (this.props.icon) {
      return (
        <AudioComponentWrapper
          role="button"
          tabIndex="0"
          className="word"
          onClick={this.togglePlay}
        >
          <span>{this.props.children}</span>
          <AudioIconWrapper className="icon is-small is-hidden-touch">
            <FaVolumeOff />
          </AudioIconWrapper>
        </AudioComponentWrapper>
      )
    }
    else {
      return (
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events
          role="button"
          tabIndex="0"
          className="word"
          onClick={this.togglePlay}
        >
          <span>{this.props.children}</span>
        </div>
      )
    }
  }
}

Player.propTypes = {
  children: PropTypes.node.isRequired,
  source: PropTypes.string.isRequired,
  icon: PropTypes.bool,
}
Player.defaultProps = {
  icon: false,
}

export default Player
