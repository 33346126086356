/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import PropTypes from "prop-types"
import { FaMicrophoneAlt, FaMusic, FaBookOpen, FaICursor, FaAlignJustify, FaAlignCenter } from "react-icons/fa"

const WordsMusicToggle = (props) => (
  <div className="tabs is-toggle is-toggle-rounded is-centered is-pulled-right">
    <ul>
      <li className={props.toggle ? "is-active" : undefined}>
        <a>
          <span className="icon is-small">
            <FaMicrophoneAlt />
          </span>
          <span>Words</span>
        </a>
      </li>
      <li className={!props.toggle ? "is-active" : undefined}>
        <a>
          <span className="icon is-small">
            <FaMusic />
          </span>
          <span>Music</span>
        </a>
      </li>
    </ul>
  </div>
)

WordsMusicToggle.propTypes = {
  toggle: PropTypes.bool,
}
WordsMusicToggle.defaultProps = {
  toggle: false,
}

const ReadSpellToggle = (props) => (
  <div className="tabs is-toggle is-toggle-rounded is-centered">
    <ul>
      <li className={props.toggle ? "is-active" : undefined}>
        <a>
          <span className="icon is-small">
            <FaBookOpen />
          </span>
          <span>Read</span>
        </a>
      </li>
      <li className={!props.toggle ? "is-active" : undefined}>
        <a>
          <span className="icon is-small">
            <FaICursor />
          </span>
          <span>Spell</span>
        </a>
      </li>
    </ul>
  </div>
)

ReadSpellToggle.propTypes = {
  toggle: PropTypes.bool,
}
ReadSpellToggle.defaultProps = {
  toggle: false,
}

const ParaPhraseToggle = (props) => (
  <div className="tabs is-toggle is-toggle-rounded is-centered">
    <ul>
      <li className={props.toggle ? "is-active" : undefined}>
        <a>
          <span className="icon is-small">
            <FaAlignJustify />
          </span>
          <span>Paragraphs</span>
        </a>
      </li>
      <li className={!props.toggle ? "is-active" : undefined}>
        <a>
          <span className="icon is-small">
            <FaAlignCenter />
          </span>
          <span>Phrases</span>
        </a>
      </li>
    </ul>
  </div>
)

ParaPhraseToggle.propTypes = {
  toggle: PropTypes.bool,
}
ParaPhraseToggle.defaultProps = {
  toggle: false,
}

export { WordsMusicToggle, ReadSpellToggle, ParaPhraseToggle }
