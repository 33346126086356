import React from "react"
import PropTypes from "prop-types"
import { FaStar } from "react-icons/fa"

const Stars = (props) => {
  const items = []
  props.pattern.split("").map((value, index) => {
    if (value === "x" || value === "s") {
      items.push(<FaStar key={index} size="1.25em" color="#ffd150" />)
    }
    if (value === "X") {
      items.push(<FaStar key={index} size="1.75em" color="#ffd150" />)
    }
    return true
  })
  return items
}

Stars.propTypes = {
  pattern: PropTypes.string.isRequired,
}

export default Stars
